body {
    overflow: auto !important;
}

.accordion-button::after {
    content: none !important;
}

.fixed-to-bottom{
    position: fixed;
    bottom: 30px;
    right: 20px; 
}

/* Mainly used in category display */
.custom-tabs {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
    /* border-bottom: 1px solid #DDD; */
    -ms-overflow-style: none; /*// IE 10+*/
    overflow: -moz-scrollbars-none; /*// Firefox*/
    padding: 0px;
}

.custom-tabs>li{
    padding: 2px 10px 2px 10px;
    list-style-type: none;
    text-align: center;
    cursor: pointer;
    white-space: pre;
    font-size: 0.875rem;
}

.custom-tabs::-webkit-scrollbar {
    display: none; /*Safari and Chrome*/
}

.custom-tabs .active {
    background-color: #00A1C9;
    color: #fff;
}

@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .navbar-nav > .nav-item {
        border-bottom: 1px #cdcdcd solid;
    }
    .navbar-nav > .nav-item:last-child {
        border-bottom: 0px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .navbar-nav > .nav-item {
        border-bottom: 1px #cdcdcd solid;
    }
    .navbar-nav > .nav-item:last-child {
        border-bottom: 0px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .navbar-nav > .nav-item {
        border-bottom: 1px #cdcdcd solid;
    }
    .navbar-nav > .nav-item:last-child {
        border-bottom: 0px;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {}

/* XX-Large devices (larger desktops) */
/* No media query since the xxl breakpoint has no upper bound on its width */


/* mobile navbar sidebar under lg breakpoint */
@media (max-width: 992px) {
    
    .navbar-collapse.collapsing .navbar-nav {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -45%;
        transition: all 0.2s ease;
    }

    .navbar-collapse.show .navbar-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        height: auto;
        width: 45%;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        overflow-y: auto;
    }
}